import { Link } from 'gatsby';
import * as React from 'react';

import { LanguageContext } from '../../../context/LanguageContext';
import closeIcon from '../shared/img/close-icon.svg';
import contactIcon from '../shared/img/contact-icon.svg';
import smallLocationIcon from '../shared/img/globe.svg';
import sorpetalerLogo from '../shared/img/logo.svg';
import menuIcon from '../shared/img/navigation-icon.svg';
import blueIcon from '../shared/img/phone-icon-blue-32.svg';

import * as styles from './BottomBar.module.less';

interface IBottomBarProps {
  showBottomBar: boolean;
  toggleBottomBar: () => void;
  isWidget?: boolean;
}

export const BottomBar = ({
  showBottomBar,
  toggleBottomBar,
  isWidget,
}: IBottomBarProps): JSX.Element => {
  const language = React.useContext(LanguageContext);

  return (
    <div className={styles.container}>
      <Link
        to={`${language === 'En' ? '/en/' : '/'}`}
        aria-label="Sorpetaler logo"
      >
        <img src={sorpetalerLogo} alt="sorpetaler-logo" />
      </Link>
      <ul>
        {isWidget && (
          <li>
            <a href="tel:0239391920" aria-label="phone-link">
              <img src={blueIcon} alt="" />
            </a>
          </li>
        )}
        <li>
          <Link to={`${language === 'De' ? '/en/' : '/'}`}>
            <img src={smallLocationIcon} alt="International-icon" />
          </Link>
        </li>
        <li>
          <Link
            to={`${language === 'En' ? '/en/contact/' : '/kontakt/'}`}
            aria-label="Link to contact page"
          >
            <img src={contactIcon} alt="" />
          </Link>
        </li>
        <li onClick={toggleBottomBar}>
          <img src={showBottomBar ? closeIcon : menuIcon} alt="" />
        </li>
      </ul>
    </div>
  );
};
